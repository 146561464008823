import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Advaidic Swan",
  "date": "2022-08-17T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The swan is particularly symbolic in Advaita Vedanta. Its ability to move through water without wetting its feathers alludes to the life of the realized man living his life totally unaffected by the world. In its flight, it represents the supreme spirit escaping the bondage of samsara.`}</p>
    <p>{`The Sanskrit for swan is Hamsa and the title Parama Hamsa is given to the highest ascetics, as in Ramakrishna Paramahamsa.`}</p>
    <p>{`If the word Hamsa is repeated as a mantra, it mutates into Soham, meaning ‘I am he’.`}</p>
    <p>{`Since ‘Aham Brahmasmi’ – I am Brahman – Hamsa stands for the non-dual reality, Brahman.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      